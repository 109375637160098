.container {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    width: 100%;

    .drag-drop-area {
        z-index: 2;
    }
      
    .data-visualization {
        height: 300px;
        width: 400px;
        z-index: 2;
    }

    .pie_chart_wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .pie_chart_legend {
            background-color: red;
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }
}

.loader-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center; 
    align-items: center;
  }
  
  .loader {
    border: 4px solid #fff;
    border-radius: 50%; 
    border-top: 4px solid transparent; 
    width: 50px; 
    height: 50px; 
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
@media (max-width: 768px) {
    .container {
        flex-direction: column;

        .drag-drop-area,
        .data-visualization {
            width: 100%;
            flex: unset; /* Reset flex property */
        }

        .data-visualization {
            display: flex;
            justify-content: center;
        }
    }
}
  