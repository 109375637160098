.login-background{
    //background: rgba(255, 255, 255, 0.7); 
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .etl_loading {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;
      position: fixed;
      width: 100%;
    }
    
    .etl_loading__dot {
      animation: dot ease-in-out 1s infinite;
      background-color: grey;
      display: inline-block;
      height: 20px;
      margin: 10px;
      width: 20px;
      border-radius: 50px;
    }
    
    .etl_loading__dot:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    
    .etl_loading__dot:nth-of-type(3) {
      animation-delay: 0.3s;
    }
    
    @keyframes dot {
      0% { background-color: grey; transform: scale(1); }
      50% { background-color: #fff; transform: scale(1.3); }
      100% { background-color: grey; transform: scale(1); }
    }

    h1.logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: -250px;
      color: white;
      .oracle_app_title {
        display: flex;
        align-items: center;
        .logo-icon {
          margin-right: 10px;
        }
        span {
          line-height: 40px;
        }
        svg {
          width: 40px;
          path {
            fill: white;
          }
        }
        .logo-icon {
          display: flex;
          align-items: center;
        }
        .logo-txt {
          margin-left: 20px;
          width: 160px;
          display: flex;
          align-items: center;
         margin-top: 7px;
          svg {
            width: 200px;
            height: auto;
          }
        }
      }

    }
}

.logo-txt {
  margin-left: 20px;
  margin-top: 2px;
  position: absolute;
}

.oracle-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 10px 20px;
    position: absolute;
    width: 100%;

    .logo_wrapper {
      display: flex;
      align-items: start;
      justify-content: start;
    }

    .logo {
      max-width: 100px;
      height: auto;
    }

    .login_button {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      cursor: pointer;
      svg {
        color: #011c3c;
      }
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .oracle_app_title {
      color: #002957;
      font-weight: 400;
      font-size: 25px;
      font-weight: 700;
      display: flex;
      align-items: center;
      span.logo-icon {
        width: 30px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        svg {
          path {
            fill: #002957;
            &:last-child {
              fill: #9cdf2c;
            }
          }
        }
      }
      .logo-txt {
        height: 30px;
        //margin-top: 7px;
        svg {
          width: 120px;
          path {
            fill: #002957;
          }
        }
      }
    }
}