// LoginPage.scss

.login-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400px;
    margin: auto;
    padding: 20px 20px 40px 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  
    h1 {
      color: #002957;
      font-size: 24px;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }

    label {
        float: left;
        margin: 20px 0 10px 0;
    }
  
    input {
      margin-top: 5px;
    }
  
    button {
      width: 100%;
      padding: 10px;
      background-color: #002957;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      margin-top: 30px;
  
      &:hover {
        background-color: #2980b9;
      }
    }
  }
  
  .notification {
    position: relative;
    max-width: 400px;
    margin: 20px auto;
    padding: 15px;
    background-color: #e74c3c;
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  
    p {
      margin: 0;
    }
  
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
    }
  }
  

  input {
    width: 96%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;

    &::placeholder {
      color: #aaa;
    }
  }