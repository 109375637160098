.branding-wrapper {
  margin: 2rem auto;
  width: 100%;
  //max-width: 750px;
  // .ant-upload.ant-upload-select-picture-card {
  //     border-radius: 100%;
  // }
  a {
    color: var(--primary-link-color)!important;
    text-decoration: underline;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
  }
  label[for='basic_logo'] {
    opacity: 0;
  }
  h1 {
    font-size: 22px !important;
    font-weight: 600 !important;
    text-align: left;
    margin-bottom: 2rem !important;
    text-transform: uppercase;
    opacity: 0.8;
  }
  .box-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0 0 16px;
    background-color: #ffffff;
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-radius: 6px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    .box {
      padding: 42px 24px 50px;
      color: rgba(0, 0, 0, 0.88);
      border-bottom: 1px solid rgba(5, 5, 5, 0.06);
      background-color: #ffffff;
      border-radius: 6px 6px 0 0;
      &.all-brands-list {
        padding-left: 5rem;
        padding-top: 1rem;
        .brand {
          border-bottom: 1px dashed #cbd5e0;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
        }
      }
      &.flex {
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
      }
    }

    // .branding-header, form {
    //     margin: 0 auto;
    // }
  }
  .logo-img {
    max-width: 150px;
    margin: 1rem auto;
    display: block;
  }
  .ant-upload.ant-upload-select {
    display: unset;
  }
  .ant-skeleton-element {
    margin: 1rem 0;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .your-brand-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .color-palette {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    margin-top: 10px;
    span {
      width: 10px;
      height: 10px;
      background-color: azure;
      margin-right: 0.5rem;
      border-radius: 1px;
      border: 1px solid #3333331e;
    }
  }
  .ant-skeleton-element .ant-skeleton-avatar-lg {
    width: 140px;
  }
  .color-palette-wrapper {
    .selected-wrapper {
      display: flex;
      align-items: center;
    }
    .ant-upload {
      width: 100%;
    }
    .theme.ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin-bottom: 0;
      }
      .color-palette {
        display: flex;
        align-items: center;
        margin-left: 2rem;
        margin-top: 10px;
        span {
          width: 10px;
          height: 10px;
          background-color: azure;
          margin-right: 0.5rem;
          border-radius: 1px;
          border: 1px solid #3333331e;
        }
      }
      .ant-upload {
        span.ant-upload {
          display: flex;
          align-items: flex-start;
        }
      }
    }
  }
}
