.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #3498db;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.drag_and_drop_wrapper {
  max-width: 100vw;
  display: flex;
  justify-content: center;

  .drag_and_drop {
    max-width: 1000px;
    display: flex;

    margin: 2rem 2rem 12px 2rem;
    span.ant-upload-wrapper {
      display: block;
    }
  }
}

.ant-upload-drag-container {
  padding: 0 100px;
}

.cursor_pointer {
  cursor: pointer;
}