.toggle-button {
    width: 50px;
    position: absolute;
    z-index: 1;
    right: 60px;
    top: 20px;

    .switch {
        position: relative;
        display: inline-block;
        width: 35px;
        height: 19px;
    }
      
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }
      
      
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }
    
    .slider:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 3px;
        bottom: 3px;
        background-image: url('../assets/sun.svg'); /* URL of your SVG image */
        background-size: cover; /* Adjust size as needed */
        background-repeat: no-repeat; /* Prevent repeating the background */
        -webkit-transition: .4s;
        transition: .4s;
    }
    
    input:checked + .slider {
        background-color: #002957;
    }
    
    input:focus + .slider {
        box-shadow: 0 0 1px #002957;
    }
    
    input:checked + .slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }
    
    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }
  
    .slider.round:before {
        border-radius: 50%;
    }


    .slider_moon {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }
    
    .slider_moon:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 3px;
        bottom: 3px;
        background-image: url('../assets/moon.svg'); /* URL of your SVG image */
        background-size: cover; /* Adjust size as needed */
        background-repeat: no-repeat; /* Prevent repeating the background */
        -webkit-transition: .4s;
        transition: .4s;
    }
    
    input:checked + .slider_moon {
        background-color: #002957;
    }
    
    input:focus + .slider_moon {
        box-shadow: 0 0 1px #002957;
    }
    
    input:checked + .slider_moon:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }
    
    /* Rounded slider_moons */
    .slider_moon.round {
        border-radius: 34px;
    }
  
    .slider_moon.round:before {
        border-radius: 50%;
    }

}