.app_header_button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    position: relative;
    cursor: pointer;
}

.app_header_button_panel {
    position: absolute;
    right: -30px;
    top: 10px;

    .button_wrapper {
        margin-top: 20px;
    }
}

.app_header_logout_button {
    position: relative;
    background: transparent;
    top: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.center_vertically {
    display: flex;
    align-items: center;
}

.rapid-avatar-wrapper .rapid-avatar {
    background-color: #002957;
}