.rapid {
  // CareSource basics colors

  //\\caresource branding//
  --primary-color: #6b0074;
  --neutral-link-color: #4a5568;
  --primary-link-color: #6b0074;
  --selected-text-color: #ffffff;
  --regular-text-color: #1a202c;
  //branding\\

  $hap-orange: #fe7200;
  $cs-purple-1: #a05eb5;
  $cs-purple-2: #84329b;
  $cs-purple-3: #d7a9e3;
  $cs-gray: #5f6165;

  --hap-orange: #fe7200;
  --hap-orange-hover: #fe720032;
  --cs-purple-1: #a05eb5;
  --cs-purple-2: #84329b;
  --cs-purple-3: #d7a9e3;
  --cs-gray: #5f6165;

  --truecare-blue: #0c4b74;
  --truecare-blue-hover: #0c4a742d;

  --truecare-light-blue: #3fd2d9;

  // CareSource purple primary spectrum
  $cs-purple-2-primary-100: #c3d4e7;
  $cs-purple-2-primary-200: #90afd1;
  $cs-purple-2-primary-300: #7599c3;
  $cs-purple-2-primary-400: #507aaa;
  $cs-purple-2-primary-500: #3a6699;
  $cs-purple-2-primary-600: #30619a;
  $cs-purple-2-primary-700: #073973;
  $cs-purple-2-primary-800: #002957;
  $cs-purple-2-primary-900: #001f43;
  $cs-purple-2-primary-alt--darkest: #3a2360;
  $cs-purple-2-primary-alt--dark: #653794;
  $cs-purple-2-primary-alt: #84329b;
  $cs-purple-2-primary-alt--light: #aa68c1;
  $cs-purple-2-primary-alt--lightest: #e3c7ec;

  --white: #{$white};
  --black: #{$black};

  --neutral-100: #{$neutral-100};
  --neutral-200: #{$neutral-200};
  --neutral-300: #{$neutral-300};
  --neutral-400: #{$neutral-400};
  --neutral-500: #{$neutral-500};
  --neutral-600: #{$neutral-600};
  --neutral-700: #{$neutral-700};
  --neutral-800: #{$neutral-800};
  --neutral-900: #{$neutral-900};

  --primary-alt--darkest: #{$cs-purple-2-primary-alt--darkest};
  --primary-alt--dark: #{$cs-purple-2-primary-alt--dark};
  --primary-alt: #{$cs-purple-2-primary-alt};
  --primary-alt--light: #{$cs-purple-2-primary-alt--light};
  --primary-alt--lightest: #{$cs-purple-2-primary-alt--lightest};

  --primary-100: #{$cs-purple-2-primary-100};
  --primary-200: #{$cs-purple-2-primary-200};
  --primary-300: #{$cs-purple-2-primary-300};
  --primary-400: #{$cs-purple-2-primary-400};
  --primary-500: #{$cs-purple-2-primary-500};
  --primary-600: #{$cs-purple-2-primary-600};
  --primary-700: #{$cs-purple-2-primary-700};
  --primary-800: #{$cs-purple-2-primary-800};
  --primary-900: #{$cs-purple-2-primary-900};

  /* Shadows */
  --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  --shadow-outline: 0 0 0 3px rgba(55, 126, 253, 0.5);
  --shadow-outline-inner: inset 0 0 0 3px rgba(55, 126, 253, 0.5);
  --shadow-outline-neutral: 0 0 0 3px rgba(160, 174, 192, 0.5);
  --shadow-outline-teal: 0 0 0 3px rgba(31, 221, 203, 0.5);
  --shadow-outline-danger: 0 0 0 3px rgba(255, 92, 56, 0.5);
  --shadow-outline-warning: 0 0 0 3px rgba(247, 201, 72, 0.5);
  --shadow-none: none;
  --shadow-outline-inset: inset 0 0 0 calc(2px) rgba(164, 202, 254, 0.7);

  //VARS FROM REACT COMPONENTS LIBRARY
  //overwrites for variables used in components library, should only include the ones for the components being used. If these specific variables are not added, the ones in the library will be applied.
  --border-color: var(--neutral-400);
  --border: 1px solid var(--border-color);

  --selected-color: var(--primary-800);
  --selected-focus-color: var(--primary-900);
  --selected-text-color: var(--white);

  --disabled-color: var(--neutral-500);
  --disabled-text-color: var(--neutral-800);
  --disabled-button-color: var(--neutral-700);

  --background-color: var(--white);
  --background-color--primary: var(--primary-100);
  --background-color--success: var(--success-200);
  --background-color--warning: var(--warning-200);
  --background-color--error: var(--danger-100);

  --text-color: var(--neutral-800);
  --text-color--disabled: var(--disabled-color);
  --text-color--primary: var(--primary-900);
  --text-color--success: var(--success-900);
  --text-color--warning: var(--warning-900);
  --text-color--error: var(--danger-900);

  --label-color: var(--neutral-700);
  --title-color: var(--primary-900);
  --link-color: var(--primary-600);

  //default section color when no other color is provided
  --section-color: var(--title-color);

  //buttons
  --button-opacity--disabled: 0.5;
  --button-primary-base-color: var(--primary-600);

  --button-primary-text-color: var(--white);
  --button-primary-background-color--hover: var(--primary-800);

  --button-outline-primary-text-color: var(--primary-500);
  --button-outline-primary-background-color: var(--white);
  --button-outline-primary-border-color: var(--primary-500);

  --button-outline-primary-text-color--hover: var(--primary-800);
  --button-outline-primary-background-color--hover: var(--primary-100);
  --button-outline-primary-border-color--hover: var(--primary-800);

  --button-outline-primary-background-color--disabled: var(--neutral-100);

  --button-outline-danger-text-color: var(--danger-500);
  --button-outline-danger-background-color: var(--white);
  --button-outline-danger-border-color: var(--danger-500);

  --button-outline-danger-text-color--hover: var(--danger-800);
  --button-outline-danger-background-color--hover: var(--danger-200);
  --button-outline-danger-border-color--hover: var(--danger-800);

  --stepper-button-background-color: var(--neutral-700);

  //used in special clickable elements (such collapse trigger and close dialog button, tree nodes)
  --trigger-color: var(--neutral-700);
  --trigger-color--active: var(--neutral-900);
  --trigger-background-color: var(--neutral-200);
  --trigger-background-color--hover: var(--neutral-300);
  --trigger-background-color--focus: var(--neutral-300);

  --clickable-text-color--hover: var(--neutral-200);

  //used in overlayed items such dialogs or dropdown menus
  --popover-text-color: var(--text-color);
  --popover-title-color: var(--neutral-700);
  --popover-overlay-color: var(--neutral-700);
  --popover-background-color: var(--background-color);
  --popover-footer-background-color: var(--neutral-200);
  --popover-clickable-text-color: var(--text-color);
  --popover-clickable-text-color--hover: var(--selected-text-color);
  --popover-clickable-text-color--active: var(--selected-text-color);
  --popover-clickable-text-background-color--hover: var(--primary-500);
  --popover-clickable-text-background-color--active: var(--primary-500);

  //used in avatar
  --avatar-background-color: var(--primary-500);
  --avatar-color: var(--white);

  //used in breadcrumb
  --separator-color: var(--neutral-600);
  --breadcrumb-background-color--dragover: var(--neutral-300);

  //used in context menu and dropdown
  --context-menu-parent-item-color--active: var(--primary-900);
  --context-menu-parent-item-background-color--active: var(--primary-100);

  //used in sidebar multilevel
  --sidebar-multilevel-background-color: var(--neutral-300);
  --sidebar-multilevel-button-text-color: var(--neutral-900);
  --sidebar-multilevel-button-text-color--hover: var(--primary-100);
  --sidebar-multilevel-button-text-color--focus: var(--primary-100);
  --sidebar-multilevel-nav-item-text-color: var(--neutral-900);
  --sidebar-multilevel-nav-item-text-color--hover: var(--neutral-900);
  --sidebar-multilevel-nav-item-text-color--focus: var(--neutral-900);
  --sidebar-multilevel-nav-item-text-color--active: var(--white);
  --sidebar-multilevel-nav-item-background-color--hover: var(--neutral-400);
  --sidebar-multilevel-nav-item-background-color--focus: var(--neutral-500);
  --sidebar-multilevel-nav-item-background-color--active: var(--neutral-500);
  --sidebar-multilevel-nav-item-border-color--focus: var(--neutral-800);
  --sidebar-multilevel-group-background-color: transparent;

  //used in tooltip
  --tooltip-background-color: var(--neutral-900);
  --tooltip-text-color: var(--white);

  //used in toggle group
  --toggle-group-background-color: var(--neutral-300);
  --toggle-group-option-text-color: var(--neutral-600);
  --toggle-group-option-border-color--hover: var(--neutral-600);
  --toggle-group-option-text-color--hover: var(--neutral-900);

  //used in spinner
  --spinner-overlay-color: hsla(214, 20%, 69%, 0.3);
  --spinner-overlay-color--light: hsla(214, 20%, 69%, 0.15);
  --spinner-color: var(--primary-500);
}
