.login-background_wrapper  {
  background-image: url("../assets/coll-Intel-1.png");
  background-color: #002957;
  background-repeat: no-repeat;
  background-size: 70% auto;
  background-position: -80% -85%;

}

.home-page {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: -5px;

    :where(.css-dev-only-do-not-override-1uweeqc).ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon .anticon {
      color: #002957;
    }

    .home-page_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 25px;
    }

    .width_40 {
      width: 40px;
    }
  
    .upload-area {
      min-width: 500px;
      width: 100%;
      min-height: 200px;
      border: 2px dashed #ddd;
      background-color: #f9f9f9;
      transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
    }

    .oracle_s3_subtitle {
      margin-bottom: 15px;
    }

    .s3_token_input_holder {
      margin: 10px 0 30px 0;

      input {
        width: 40% !important;
      }

      label {
        margin-bottom: 5px;
      }
    }
    
    .upload-area.dragging {
      background-color: #e0e0e0;
      border-color: #999;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }

    .s3_bucket_name_holder {
      margin: 1rem auto;
      font-size: 26px;
      font-weight: 600;
    }

    .input_holder {
      width: 400px;
      display: flex;
      align-items: center;
    }

    .edit_icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-left: 10px;
      padding: 5px;
    }

    .edit_icon:hover {
      background-color: #d6d6d6;
    }
    
    .drag-text {
      margin: 0;
      padding: 40px;
      text-align: center;
      font-size: 18px;
      color: #666;
    }

    .back_button {
      background-color: #cccccc3b;
      border-radius: 20px;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      cursor: pointer;
      svg {
        color: #011c3c;
      }
    }

    .back_button:hover {
      background-color: #afafaf61;
    }
    
    .download_button_width{
      width: 200px;
    }
    .refresh_button_width{

      .anticon {
        margin: 0;
      }
    }
    .download_button {
      background-color: #002957 ;
      color: #fff;
      border-radius: 5px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
      cursor: pointer;
    }

    .download_button:hover {
      background-color: #011c3c;
    }

    .disabled_button {
      background: hsl(212, 90%, 12%, 0.5);
      cursor: not-allowed;
    }

    .disabled_button:hover {
      background: hsl(212, 90%, 12%, 0.5);
    }
  
    .table-container {
      /* Add your styles for the table container */
      thead {
        tr {
          th {
            color: #002957;
            text-transform: uppercase;
          }
        }
      }
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
  
      th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
  
      th {
        background-color: #f2f2f2;
      }
  
      tbody tr:nth-child(even) {
        background-color: #f9f9f9;
      }
  
      input[type="checkbox"] {
        margin-right: 5px;
      }
    }
  }

.flex {
  display: flex;
}

.align_center {
  display: flex;
  align-items: center;
}

.flex_direction_column {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.connect-button {
  background-color: #002957;
  color: #fff;
  border-radius: 5px;
  height: 40px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

section.header {
  padding: 1rem;
  // overflow: hidden;
  min-height: 300px;
  background-color: #002957;
  color: white;
  border-radius: 10px;
  position: relative;
  margin-bottom: 1rem;
  .bg-img {
    position: absolute;
    background-image: url("../assets/coll-Intel-1.png");
    background-position: left bottom;
    color: white;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: 40% 100%;
    background-position: -33% 90%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 100%;
    height: 100%;
    pointer-events: none;
    top: 0px;
  }
  @media (max-width: 768px) {
    .bg-img {
      position: absolute;
      background-image: url("../assets/coll-Intel-1.png");
      background-position: left bottom;
      color: white;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: -350px 100%;
      transform: scaleX(-1);
      width: 100%;
      height: 100%;
      pointer-events: none;
      top: 0px;
    }
  }
  
  .ant-upload {
    p, svg {
      color: white!important;
    }
    svg {
      opacity: 0.5;
      transition: all 0.3s
    }
    &:hover {
      border-color: white!important;
      svg {
        opacity: 0.8;
      }
    }
  }
  .ant-upload-list {
    color: white;
    margin-bottom: 1.5rem;
    svg {
      color: white;
      font-size: initial;
    }
    .ant-upload-list-item {
      &:hover {
        background-color: rgba(0, 0, 0, 0.27);
      }
    }
  }
}

.dark_blue_hover {
  background-color: #3366cc !important;
}

.dark_blue {
  background-color: #002957 !important;
}

.ant-upload.ant-upload-drag {
  height: 250px !important;
  border-radius: 10px !important;
}

.button_right_position {
  width: 100%;
  display: flex;
  justify-content: end;
  padding-right: 25px;
}