.project_title {
    color: #2c88d9;
    text-decoration: underline;
    cursor: pointer;
}

.table_wrapper {
    min-height: 70vh;
}

.table_header {
    

    .table_title_flex {
        width: 100%;
        display: flex;
        justify-content: space-between;

        h3 {
            color: #002957;
            margin: 0 0 0.5rem 0;
            text-transform: uppercase;
        }

    }

    .filter-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        .zip_filter_holder {
            width: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            position: relative;
            input {
                width: 100%;
                border-radius: 6px;
                padding-right: 40px;
            }
            button {
                position: absolute;
                height: 30px;
                right: 5px;
                background-color: transparent;
                span {
                    margin-right: 0;
                    svg {
                        color: #3333335e;
                    }
                }
    
            }
        }
    }
}

.center_download_horizontally {
    display: flex;
    justify-content: center;

    .ant-btn-icon {
        margin-right: 0;
    }
}

.position_relative {
    position: relative;
    z-index: 2;
}

.countdown_timer_wrapper {
    width: 100px;
    display: flex;
    justify-content: end;
    position: absolute;
    top: 10px;
    color: #626262;
    right: 15px;
    z-index: 1;
}

.individual_download_button {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-btn.ant-btn-icon-only .anticon {
    margin-right: 0 !important;
    color: #002957 !important;
    
}

.ant-btn-link {
    color: #002957 !important;
}

.ant-table-cell {
    padding: 8px 10px !important;
} 

// LIGHT MODE
.older-than-30-minutes-light {
    background-color: #cff6cf !important;
}

.older-than-30-minutes-light:hover {
    background-color: #cff6cf !important;
}

.older-than-30-minutes-dark {
    background-color: #e0ffe0 !important;
}

.older-than-30-minutes-dark:hover {
    background: #e0ffe0 !important;
}

// DARK MODE
.older-than-30-minutes-light_dm {
    background-color: #97db978c !important
}

.older-than-30-minutes-light_dm:hover {
    background-color: #97db978c !important
}

.older-than-30-minutes-dark_dm {
    background-color: #cbedcb8c !important
}

.older-than-30-minutes-dark_dm:hover {
    background: #cbedcb8c !important
}

td.ant-table-column-sort {
    background-color: transparent !important;
}

// TABLE
:where(.css-dev-only-do-not-override-1uweeqc).ant-table-wrapper td.ant-table-column-sort {
    background: transparent !important;
}

.ant-table-cell-row-hover {
    background: rgba(0, 0, 0, 0.05) !important;
}

.filter_name_wrapper {
    margin: 10px 0 15px 0;
    display: flex;
    align-items: center;
    
    .filter_name {
        display: inline-block;
        font-weight: 500;
        border: 1px solid #002957;
        border-radius: 5px;
        padding: 5px 10px;
        margin-left: 10px;
    }
    
    .close_icon {
        height: 100%;
        display: flex;
        align-items: center;
        margin: 4px 3px 0 10px;
        cursor: pointer;
    }
}


.filter_wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    background-color: red;

    .filter_box {
        position: absolute;
        top: 5px;
        right: 0;
        width: 250px;
        background-color: #fff;
        border: 1px solid #002957;
        border-radius: 5px;
        z-index: 2;
        padding: 10px 0;
        font-weight: 500;

        .filter_item {
            color: #002957;
            cursor: pointer;
            padding: 8px 20px;
        }

        .filter_item:hover {
            color: #000;
            background-color: #f0f0f0;
        }

        .selected_filter {
            background-color: #f0f0f0;
        }
    }

}

.blue_color {
    color: #002957;
}

.small_button_width {
    width: 60px;
}

.display_none {
    display: none;;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-upload-wrapper .ant-upload-drag {
    height: 250px;
    display: flex;
    align-items: center;
}

.flip_data {
    transform: scaleX(-1);
}

.ant-upload.ant-upload-drag {
    background-color: transparent !important;
}

.ant-table-pagination.ant-pagination {
    display: flex;
    justify-content: center;
}

.ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link {
    display: flex;
    align-items: center;
}

.spin_center {
    width: 100%;
    display: flex;
    justify-content: center;
}

.ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link { 
    padding-left: 7px;
}