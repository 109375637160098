button {
        border: none;
        background-color: #002957;
        color: white;
        span {
        margin-right: 10px;
        }
        .anticon {
            svg {
                font-size: 20px;
            }
        }
}

.no_style {
    border: none;
    background-color: transparent;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #002957 !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
}

.ant-tabs-tab:hover {
    color: #002957 !important;
}

.ant-tabs-ink-bar {
    background-color: #002957 !important;
}

.ant-tabs-tab-btn {
    color: #011c3c !important; 
}

.ant-tabs-tab-btn:hover {
    font-weight: 500 !important;
}

