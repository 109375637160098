.search-button {
  padding-top: 1rem;
}
.ant-checkbox-group-item {
  text-transform: capitalize;
}
.search-table {
  margin-top: 1rem;

  .search-title-checks-wrapper {
    h2 {
      span {
        opacity: 0.5;
      }

      small {
        font-weight: 300;
        font-size: 70%;
      }
    }
  }

  .search-form-wrapper {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .ant-tabs-content-holder {
    border: none !important;
    padding: 0 !important;
  }

  .app-page__header--wrapper {
    display: none;
  }

  .ant-tabs-top > .ant-tabs-nav:before {
    display: none !important;
  }

  .dashboard-tabs {
    .ant-tabs-nav-wrap {
      background-color: transparent !important;
      justify-content: center;
    }

    .ant-tabs-tab {
      background-color: transparent;
      border: none;
      color: var(--text-color);
      opacity: 0.55;

      &:hover {
        background-color: transparent;
        color: var(--text-color);
        opacity: 1;
      }
    }

    .ant-tabs-tab-active {
      border-top-color: transparent !important;
      color: var(--text-color);
      opacity: 1;

      .ant-tabs-tab-btn {
        color: var(--text-color);
      }
    }

    .tab-title-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      svg {
        font-size: 25px;
        margin-bottom: 5px;
      }
    }
  }
  .ant-table-thead > tr > th {
    padding: 26px 16px;
  }
  .ant-table-thead {
    .ant-table-cell {
      text-transform: uppercase;
      font-weight: bold;
      opacity: 0.8;
      white-space: nowrap;
      .table-title {
        display: flex;
        align-items: center;
        font-size: 80%;
      }
      .anticon {
        margin-right: 7px;
        svg {
          font-size: 18px;
        }
      }
    }
  }

  .ant-table-cell {
    .claims-info {
      .status {
        .badge {
          font-size: 65%;
        }
      }
      div {
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    p {
      line-height: 1.2;
      margin-bottom: 7px;
      display: flex;
      align-items: center;
      justify-content: start;
      svg {
        margin-right: 5px;
        font-size: 17px;
        opacity: 0.7;
        min-width: 15px;
      }
      span {
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 350px;
        overflow: hidden;
      }
    }
    div {
      margin-bottom: 5px;
      &:last-child {
        margin-top: 0;
      }
    }
    b {
      opacity: 0.8;
      //display: flex;
      //align-items: center;
      margin-right: 5px;
      line-height: 14px;
      // margin-bottom: 5px;
      white-space: nowrap;
      display: none;
    }

    .link {
      display: block;
      color: purple;
      text-decoration: underline;
      cursor: pointer;
      font-size: 16px;
    }

    small {
      opacity: 0.7;
    }
  }
}
.copy-modal {
  .ant-checkbox-group {
    display: grid;
  }
}
.copy-modal-wrapper {
  .ant-modal-body {
    min-height: 289px;
  }
}
.CopyModal-container {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    background-color: transparent;
  }
  .copy-modal {
    padding: 0 1rem;
  }
  .mode-select {
    display: flex;
    justify-content: center;
    .ant-radio-group {
      display: flex;
      width: 100%;
      gap: 1rem;
      label {
        //margin-right: 1rem;
        //border: none;
        // &::before {
        //   display: none;
        // }
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        padding: 1rem;
        width: 100%;
        height: unset;
        text-align: center;
        //text-transform: uppercase;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        .ant-radio-button {
          & + span {
            gap: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &.ant-radio-button-wrapper-checked {
          background: var(--primary-color);
          border-color: var(--primary-color);
          color: white;
        }
      }
    }
  }
  .mode-select-title {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
    padding-bottom: 8px;
    color: #1890ff;
  }
}

.search-inputs-form .ant-form-item {
  .ant-form-item-label {
    text-align: left;
    label {
      text-transform: uppercase;
    }
  }
  .ant-row {
    flex-flow: column;
  }
}

.search-title-checks-wrapper {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  h2 {
    white-space: nowrap;
    margin-right: 2rem !important;
  }
  .ant-checkbox-wrapper {
    font-size: 12px;
  }
}

.search-page {
  .search-wrapper {
    .ant-affix {
      .search-form-wrapper {
        border-bottom: 1px solid #cbd5e0;
      }
    }
  }

  .search-checkboxes {
    margin-left: auto;
    .label-btn-wrap {
      display: flex;
      align-items: center;
      .anticon.anticon-down-square {
        margin-right: 7px;
      }
    }
  }

  .go-to-link {
    margin-left: auto;

    .label-btn-wrap {
      display: flex;
      align-items: center;
      .anticon.anticon-down-square {
        margin-right: 7px;
      }
    }
    a {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      b {
        margin: 0 5px;
      }
      svg {
        margin-right: 7px;
        font-size: 18px;
      }
    }
  }
  .search-wrapper {
    h2 {
      font-size: 22px;
      font-weight: 600;
      text-align: left;
      margin: 0;
      text-transform: uppercase;
      opacity: 0.8;
    }
    margin: 0 auto;
    overflow: hidden;
    border: 1px solid #d5d5d5;
    background-color: white;
    padding: 0rem 2rem 1rem;
    border-radius: 20px;
    // max-width: 750px;
    .ant-form-inline {
      flex-wrap: wrap;
    }
    .search-inputs-form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem;
      margin-right: 1rem;
      //justify-content: space-between;
      .ant-form-item {
        //width: 200px;
        flex: unset;
        margin-right: 0;
        // &:last-child {
        //   width: calc(400px + 1rem);
        // }
      }
    }
    .search-button {
      margin-top: 0.5rem;
    }
    .rapid-button-list {
      li {
        button {
          gap: 0.5rem;
          margin-top: 1rem;
        }
      }
    }
  }
}

.cell-actions {
  display: flex;
  gap: $base-spacing-half;
  min-width: 151px;
}

.DatePickerAutoPopulate__input-warpper {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
}

.Search__actions-wrapper {
  display: flex;
  align-items: center;
  h2.actions-title {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin: 0;
    text-transform: uppercase;
    opacity: 0.8;
    margin-right: 10px;
  }
}
